@import url(//fonts.googleapis.com/css?family=Open+Sans:700,600,300);
@font-face{
  font-family:'almoni-dl';
  font-weight:300; /*(light)*/
  font-style: normal;
  src: url(/static/media/almoni-dl-aaa-300.153d227c.eot);
  src: url(/static/media/almoni-dl-aaa-300.153d227c.eot?#iefix) format('embedded-opentype'),
  url(/static/media/almoni-dl-aaa-300.63fe857d.ttf) format('truetype'),
  url(/static/media/almoni-dl-aaa-300.77263c26.woff) format('woff');
}
@font-face{
  font-family:'almoni-dl';
  font-weight:400; /*(regular)*/
  font-style: normal;
  src: url(/static/media/almoni-dl-aaa-400.78f5cfe3.eot);
  src: url(/static/media/almoni-dl-aaa-400.78f5cfe3.eot?#iefix) format('embedded-opentype'),
  url(/static/media/almoni-dl-aaa-400.dc907e0e.ttf) format('truetype'),
  url(/static/media/almoni-dl-aaa-400.46ff697e.woff) format('woff');
}
@font-face{
  font-family:'almoni-dl';
  font-weight:700; /*(bold)*/
  font-style: normal;
  src: url(/static/media/almoni-dl-aaa-700.a6b5857d.eot);
  src: url(/static/media/almoni-dl-aaa-700.a6b5857d.eot?#iefix) format('embedded-opentype'),
  url(/static/media/almoni-dl-aaa-700.e6e3d7e7.ttf) format('truetype'),
  url(/static/media/almoni-dl-aaa-700.b8dec175.woff) format('woff');
}
@font-face{
  font-family:'almoni-dl';
  font-weight:900; /*(black)*/
  font-style: normal;
  src: url(/static/media/almoni-dl-aaa-900.99640ccd.eot);
  src: url(/static/media/almoni-dl-aaa-900.99640ccd.eot?#iefix) format('embedded-opentype'),
  url(/static/media/almoni-dl-aaa-900.9d9670f1.ttf) format('truetype'),
  url(/static/media/almoni-dl-aaa-900.2eac3fb8.woff) format('woff');
}

html {
  margin: 0;
  padding: 0;
}
body {
  direction: rtl;
  margin: 0;
  padding: 0;
  font-family: "almoni-dl", "open sans", -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color:#01537c;
  background: url(/static/media/bg-cloud.fe010f87.jpg) no-repeat top center;
  background-size: 1920px;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}





.kv-logo {
  width: 180px;
  height: 81px;
  background: url(/static/media/logo.f402f9b4.png) no-repeat top right;
  background-size: 180px 81px;
  margin: 17px 0 40px 0;
}

.head-phone {
  float:left;
}

.head-phone .r1 {
  margin-top: 40px;
  font-size:15px;
  font-weight: 700;
}

.head-phone .r2 {
  font-size:28px;
  font-weight: 700;
  line-height: 20px;
}

.person {
  width: 100%;
  border:1px solid rgba(151, 151, 151, 0.3);
  background: url(/static/media/bg-passenger.ea971b59.jpg) no-repeat top left;
  background-size: contain;
  padding: 35px 49px 49px;
  border-radius: 5px;
  box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.19), 0 0 2px 0 rgba(0, 0, 0, 0.26);

  margin-bottom:40px;
}

.avatar-passenger {
  width: 38px;
  height: 38px;
  background: url(/static/media/avatar-passenger.7931e462.svg) no-repeat top right;
  margin-left: 11px;
}

.pass-header {
  display: flex;
  font-size:25px;
  font-weight: 700;
  padding-bottom: 32px;
}

label {
  font-weight: 700;
}

.react-datepicker__close-icon::after {
  right: inherit !important;
  left: 7px;
}

.react-datepicker-wrapper {
  display: block!important;

  background: url(/static/media/picker-icon.68bfad15.svg) no-repeat 8px 8px;
  background-size: 20px 22px;
}

.react-datepicker-wrapper input {
  height: 38px;
  border: 1px solid #ced4da;
  border-radius: .25rem;
  width: 100%;
  padding: .375rem .75rem;
  background: none;
}

.ui-label {
  font-size:15px;
  color:#404042;
  font-weight: 700;
  margin-bottom: 0;
  display: block;
}

.dropdown-toggle::after {
  position: relative;
  top:3px;
}

@media only screen and (max-width: 600px) {
  .kv-logo {
    width: 180px;
    height: 81px;
    background: url(/static/media/logo.f402f9b4.png) no-repeat top right;
    background-size: 180px 81px;
    margin: 17px 0 40px 0;
  }

  .head-phone {
    float:none;
  }


  .head-phone .r1 {
    margin-top: 0px;
  }

  .head-phone .r2 {
    margin-bottom:30px;
  }
}



input::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  text-align: right;
  direction: rtl;
}
input::-moz-placeholder {
  /* Firefox 19+ */
  text-align: right;
  direction: rtl;
}
input:-ms-input-placeholder {
  /* IE 10+ */
  text-align: right;
  direction: rtl;
}
input:-moz-placeholder {
  /* Firefox 18- */
  text-align: right;
  direction: rtl;
}

.dp-invalid input {
  border-color:#dc3545;
  padding-left: calc(1.5em + .75rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23dc3545' viewBox='0 0 12 12'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: left calc(.375em + .1875rem + 25px) center;
  background-size: calc(.75em + .375rem) calc(.75em + .375rem);
}

.form-check label {
    font-weight: normal;
}

body {
  font-size:18px;
}

.center {
  text-align: center;
}
.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    -webkit-animation: App-logo-spin infinite 20s linear;
            animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.price {
  font-family: "open sans", Arial, serif;
  font-size:1.5rem;
  font-weight: 700;
}

@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

